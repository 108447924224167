import Form from '@searchtrends/webform-processor';
import Slider from './slider';

window.axios = require('axios');

window.hideGoogleMaps = function () {
    document.cookie = "showGoogleMaps=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict";

    location.reload();
}

window.allowGoogleMaps = function () {
    document.cookie = "showGoogleMaps=" + Math.floor(Date.now() / 1000) + "; path=/; SameSite=Strict";
}

window.loadMapsJs = function() {

    let token = document.head.querySelector('meta[name="maps-token"]');
    let script = document.createElement('script');

    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + token.content;

    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
    script.onload = () => {
        initMap();
    }
}

window.loadMapsImage = function () {
    allowGoogleMaps();

    document.querySelector('.google-maps-image').src = document.querySelector('.google-maps-image').dataset.src;
    document.querySelector('.google-maps-image').classList.remove('hidden');
    document.querySelector('.hideGoogleMaps').classList.remove('hideGoogleMaps');
    document.querySelector('.showGoogleMapsImage').classList.add('hidden');
}

let googleMapsImage = document.querySelector('.google-maps-image');
if (googleMapsImage) {
    if (document.cookie.replace(/(?:(?:^|.*;\s*)showGoogleMaps\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== "") {
        googleMapsImage.src = googleMapsImage.dataset.src;
        googleMapsImage.classList.remove('hidden');

        document.querySelector('.showGoogleMapsImage').classList.add('hidden');

        if (document.querySelector('.hideGoogleMaps')) {
            document.querySelector('.hideGoogleMaps').style.display = 'block';
        }
    }
}

let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});

let indexform = document.querySelector('form[name="indexform"]');
if (indexform) {
    indexform.addEventListener('submit', function (e) {
        indexform.querySelector('#hidden_trefwoord').value = document.querySelector('input[name="trefwoord"]').value;
    });
}

if (document.querySelector('map[name="region-map"]')) {
    let areas = document.querySelectorAll('map[name="region-map"] area');
    let card  = document.querySelector('#region-image');

    areas.forEach(function (area) {
        area.addEventListener('mouseover', (e) => {
            card.src = `/images/regions/${area.dataset.card}.png`;
        });
        area.addEventListener('click', (e) => {
            let searchfield = document.querySelector('#regsearch-plaats');
            searchfield.name = 'provincie';
            searchfield.value = area.dataset.title;
            if (document.querySelector('#searchform-ext-plaats')) {
                document.querySelector('#searchform-ext-plaats').value = area.dataset.title.toLowerCase()
            }
        });
    });
}

if (document.querySelector('.extended-search')) {
    document.querySelector('.extended-search').addEventListener('click', function (e) {
        e.stopPropagation();

        document.querySelector('.extended-search-collapse').classList.remove('hidden');
        document.querySelector('.extended-search').classList.remove('cursor-pointer');
    });

    document.querySelector('.extended-search-close').addEventListener('click', function (e) {
        e.stopPropagation();

        document.querySelector('.extended-search-collapse').classList.add('hidden');
        document.querySelector('.extended-search').classList.add('cursor-pointer');
    });
}

if (document.querySelector('.extended-search-compact-close')) {
    document.querySelector('.extended-search-compact-close').addEventListener('click', () => {
        document.querySelector('#regsearch-plaats').value = '';
        document.forms['searchform'].submit();
    })
}

if (document.querySelector('.extended-search-smallform .searcharrow')) {
    document.querySelector('.extended-search-smallform .searcharrow').addEventListener('click', () => {
        document.forms['searchform'].submit();
    })
}

/* Slider */
if (document.querySelector('.slider')) {
    let slider = new Slider(document.querySelectorAll('.slider .slide'));
    slider.handle();
}

/* Responsive Menu */
if (document.querySelector('.navmenu svg')) {
    document.querySelector('.navmenu svg').addEventListener('click', function (e) {
        document.querySelector('nav .navbar ul').classList.toggle('showmenu');
    });
}

/* Stats Menu */
if (document.querySelector('.adn-show-stats')) {
    document.querySelector('.adn-show-stats').addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector('.adn-stats').classList.remove('hidden');
        document.querySelector('.adn-stats').scrollIntoView({ behavior: 'smooth' });
    });
}
