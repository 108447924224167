export default class Slider {
    constructor(slides) {
        this.slides = slides;
        this.interval = null;
        this.timeout = 8000;
        this.activeSlide = 0;

        document.querySelectorAll('.slide-pagination-page').forEach((element) => {
            element.addEventListener('click', () => {
                this.showSlide(parseInt(element.dataset.sliderId));
            })
        });
    }

    nextSlide()
    {
        this.activeSlide++;
        if (this.activeSlide >= this.slides.length) {
            this.activeSlide = 0;
        }

        this.showSlide(this.activeSlide);
    }

    showSlide(slideIndex) {
        this.activeSlide = slideIndex;

        this.slides.forEach((slide, index) => {
            if (index === slideIndex) {
                slide.classList.remove('hidden');
            } else {
                slide.classList.add('hidden');
            }
        });

        clearInterval(this.interval);
        this.handle();
    }

    handle()
    {
        this.interval = setTimeout(this.nextSlide.bind(this), this.timeout);
    }
}
